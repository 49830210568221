"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@mui/material/styles");
const globalTheme = (0, styles_1.createTheme)({
    typography: {
        fontSize: 16,
        body1: {
            fontSize: 16,
        },
        body2: {
            fontSize: 14,
        },
    },
    palette: {
        primary: {
            main: '#00BCD4',
            light: '#EBFEFF',
            dark: '#07769B',
            contrastText: '#FFFFFF',
        },
        error: {
            main: '#FF5F57',
            light: '#FF572240',
        },
        secondary: {
            light: '#ffe27a',
            main: '#feca0e',
            dark: '#222',
            contrastText: '#222',
        },
        grey: {
            50: '#ddd',
            100: '#777',
            500: '#444',
            A100: '#FFFFFF',
        },
        background: {
            paper: '#FFFFFF',
            default: '#FFFFFF',
        },
        custom: {
            surfaceLight: '#FFFFFF',
            surfaceMedium: '#F5F5F5',
            surfaceDark: '#D9D9D9',
            panelSurface: '#E5F1F5',
            panelText: '#07769B',
            primary: '#00BCD4',
            primaryMedium: '#B2EBF2',
            primaryLight: '#E0F7FA',
            text: '#212121',
            textMedium: '#424242',
            textLight: '#757575',
        },
    },
    // header: {
    //   height: 60,
    // },
});
const theme = (0, styles_1.createTheme)(globalTheme, {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '&#feesable-app': {
                    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                },
                strong: {
                    color: 'inherit',
                },
                li: {
                    margin: 0,
                    '&:before': {
                        display: 'none',
                        content: 'none',
                    },
                },
                button: {
                    minWidth: 0,
                    maxWidth: 'none',
                },
                // Custom styles for Victory Lutheran College
                '#feesable .indicative-amount': {
                    fontWeight: 'normal !important',
                    color: 'white !important',
                    margin: '0px !important',
                },
                '#feesable .fee-year-estimate': {
                    fontWeight: 'normal !important',
                    color: 'white !important',
                },
                '#feesable p': {
                    margin: 'initial !important',
                },
                '.breakdown-dialog p': {
                    margin: 'initial !important',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    '&.Mui-error': {
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: globalTheme.palette.error.main,
                        backgroundColor: globalTheme.palette.error.light,
                    },
                },
                input: {
                    fontSize: 16,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    input: {
                        boxShadow: 'none',
                        '&:focus': {
                            border: 'none',
                        },
                        fontSize: 16,
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    fontSize: '1em',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                sizeLarge: {
                    paddingTop: 12,
                    paddingBottom: 12,
                },
                outlined: {
                    '&:hover': {
                        color: 'black',
                    },
                },
                root: {
                    fontSize: 16,
                    '&.pill-button': {
                        '&:hover': {
                            backgroundColor: '#00B6DF',
                        },
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: ({ ownerState }) => (Object.assign({ borderColor: theme.palette.custom.primary, color: theme.palette.custom.primary, width: 60, '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.main,
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: (0, styles_1.darken)(theme.palette.primary.main, 0.1),
                        },
                    } }, ((ownerState.meta || {}).error && {
                    borderColor: globalTheme.palette.error.main,
                    color: theme.palette.error.main,
                }))),
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                },
            },
        },
        MuiItemRoot: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: 26,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '1em',
                },
                subtitle1: {
                    fontSize: 16,
                    '@media (min-width:600px)': {
                        fontSize: 16,
                    },
                },
                caption: {
                    fontSize: '0.75em',
                },
                body2: {
                    fontSize: 14,
                },
                h1: {
                    fontSize: '4.7129em !important',
                    lineHeight: '1.167 !important',
                    '@media (min-width:600px)': {
                        fontSize: '4.7129em !important',
                    },
                    '@media (min-width:900px)': {
                        fontSize: '5.3556em !important',
                    },
                },
                h2: {
                    fontSize: '37px',
                    '@media (min-width:600px)': {
                        fontSize: '31px',
                    },
                    '@media (min-width:900px)': {
                        fontSize: '37px',
                    },
                },
                h3: {
                    fontSize: '31px',
                    '@media (min-width:600px)': {
                        fontSize: '27px',
                    },
                    '@media (min-width:900px)': {
                        fontSize: '31px',
                    },
                },
                h4: {
                    fontSize: '30px',
                    '@media (min-width:600px)': {
                        fontSize: '25px',
                    },
                    '@media (min-width:900px)': {
                        fontSize: '30px',
                    },
                },
                h5: {
                    fontSize: '25px',
                    '@media (min-width:600px)': {
                        fontSize: '20px',
                    },
                    '@media (min-width:900px)': {
                        fontSize: '25px',
                    },
                },
                h6: {
                    fontSize: '19px',
                    '@media (min-width:600px)': {
                        fontSize: '15px',
                    },
                    '@media (min-width:900px)': {
                        fontSize: '19px',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '0.75em',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                },
                filled: {
                    fontSize: 16,
                },
                select: {
                    padding: 16,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                },
            },
        },
        MuiBox: {
            styleOverrides: {
                root: {
                    fontSize: '1em',
                },
            },
        },
    },
});
exports.default = (0, styles_1.responsiveFontSizes)(theme, { factor: 2.2 });
