import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import EditIcon from '@mui/icons-material/Edit'
import RepeatIcon from '@mui/icons-material/Repeat'
import SubjectIcon from '@mui/icons-material/Subject'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { usePeriods } from '../../hooks/usePeriods'
import { formatNumberCurrency } from '../../services/TextHelper'
import { EstimateCallout } from '../parts/EstimateCallout'
import { InfoPopup } from '../parts/InfoPopup'
import { QuotePage } from '../parts/QuotePage'
import { EstimateChild } from './EstimateChild'
import { useSetCurrentPage } from '../../hooks/useCurrentPage'
import { List, ListItem, ListItemText } from '@mui/material'
import { useFormData } from '../../hooks/useFormData'
import FeesableDialog from '../FeesableDialog/FeesableDialog'
import { AdditionalFields } from '../parts/AdditionalFields'
import { MacroWrapper } from '../../MacroWrapper'
import MacroRenderer from '../../MacroRenderer'

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: 0,

  '&.MuiAccordion-root': {
    marginBottom: '0px',
    marginTop: '0px',
    boxShadow: 'none',
    border: '1px solid',
    borderColor: theme.palette.custom.primaryLight,
  },

  '& h1, h2, h3, h4, h5, h6': {
    '&.MuiTypography-root': {
      color: theme.palette.primary.contrastText,
    },
  },

  '& .MuiAccordionSummary-root': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginBottom: 0,

    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

  '& .MuiAccordionSummary-content': {
    position: 'relative',
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    marginRight: '-8px',
  },

  '& .year-summary': {
    backgroundColor: theme.palette.custom.primary,
  },

  '& .year-margins': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginBottom: 0,

    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

  '& .MuiAccordionDetails-root': {
    padding: 0,
  },

  '& .year-amount': {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      '& h1': {
        fontSize: '4em',
      },
    },
  },

  '& .indicative': {
    position: 'absolute',
    top: -14,
    fontSize: '0.9em',
    fontStyle: 'italic',
    color: theme.palette.primary.light,
  },

  '& .year-description': {
    width: '100%',
    float: 'right',
    textAlign: 'right',
    fontStyle: 'italic',
    marginTop: '10px',
    paddingLeft: '10px',
  },
}))

const PaymentDetailsAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.custom.surfaceMedium,
  padding: 0,

  '&.MuiAccordion-root': {
    marginBottom: '0px',
    marginTop: '0px',
    boxShadow: 'none',
    border: 'none',
    backgroundColor: theme.palette.custom.surfaceMedium,
    color: theme.palette.custom.panelText,
  },

  '& .MuiAccordionSummary-root': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginBottom: 0,

    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    minHeight: 0,
  },

  '& .MuiAccordionSummary-content': {
    position: 'relative',
    margin: 0,
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    marginRight: '-8px',
  },

  '& .MuiAccordionDetails-root': {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    paddingBottom: theme.spacing(1),
  },

  '& .MuiListItem-root': {
    paddingLeft: 0,
    paddingRight: 0,
  },

  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 0,
  },
}))

const UpdateChildNamesButton = styled(Button)(() => ({
  display: 'block',
  backgroundColor: '#FFD5C7',
  ':hover': {
    backgroundColor: '#FFD5C7',
  },
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  color: '#FF5722',
  borderRadius: 0,
}))

const BreakdownButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  position: 'absolute',
  bottom: 0,
  '&:hover': {
    backgroundColor: '#00000028',
    color: theme.palette.primary.light,
  },
}))

const StyledPeriodButton = styled('li')(({ theme }) => ({
  float: 'left',
  listStyle: 'none',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderRight: `1px solid ${theme.palette.primary.light}`,
  color: theme.palette.primary.light,
  textTransform: 'uppercase',
  cursor: 'pointer',
  fontSize: '14px',

  '& p': {
    color: theme.palette.primary.light,
  },

  '&:hover': {
    backgroundColor: '#ffffff8f',
    color: theme.palette.primary.main,
  },

  '&:hover p': {
    color: theme.palette.primary.main,
  },

  '&.active': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },

  '&.active p': {
    color: theme.palette.primary.main,
  },

  '&:last-child': {
    borderRight: 'none',
  },

  '&::before': {
    content: '"none!important"',
  },
}))

const StyledPeriodOnMobile = styled(Select)(({ theme }) => ({
  marginTop: '14px',
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,

  '& .MuiSelect-icon': {
    color: theme.palette.primary.main,
  },
}))

const EstimateYear = ({
  breakdownYearData,
  index,
  config,
  selectPeriodHandler,
  setIsChildNameDialogOpen,
  showUpdateChildName,
  onViewBreakdown,
  showIndicative,
}) => {
  const [flags, setFlags] = useState([])
  const [isQuotePageOpen, setIsQuotePageOpen] = useState(false)
  const periods = usePeriods()
  const preventDefault = (event) => event.preventDefault()
  const periodsInfos = [config.setup.periods.info, ...config.setup.periods.available.map((p) => p.info)]
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const setCurrentPage = useSetCurrentPage()
  const [expanded, setExpanded] = useState(false)
  const [extrasValidationMessage, setExtrasValidationMessage] = useState()
  const theme = useTheme()
  const formData = useFormData()
  const [currentStudentIndex, setCurrentStudentIndex] = useState(0)
  const [extrasOpen, setExtrasOpen] = useState(false)

  const handleExpanded = () => {
    setExpanded((prev) => !prev)
  }

  const handleSelectPeriod = (e) => {
    selectPeriodHandler(e.target.value)
  }
  const generateExtrasMessage = (students) => {
    const studentNames = students.map(
      (student) => student.name?.trim() || `${breakdownYearData.students.find((e) => e.id === student.id)?.name}`,
    )
    if (studentNames.length === 1) {
      return `${config.terms.extrasMissingExtras} ${studentNames[0]}?`
    }

    const lastStudent = studentNames.pop()
    return `${config.terms.extrasMissingExtras} ${studentNames.join(', ')} and ${lastStudent}?`
  }

  const getStudentsWithoutExtras = () => {
    if (!formData?.students) return []

    return formData.students.filter((student) => {
      return !student.extras || student.extras.every((extra) => !extra.selected?.length)
    })
  }

  const onValidateExtras = () => {
    const studentWithoutExtras = getStudentsWithoutExtras()
    if (studentWithoutExtras.length === 0 || config.terms.extrasMissingExtras == null) {
      setIsQuotePageOpen(true)
      return
    }

    const message = generateExtrasMessage(studentWithoutExtras)
    setExtrasValidationMessage(message)
  }

  useEffect(() => {
    if (!breakdownYearData || !config.fields.data) return false
    const _flags = []

    config.fields.data.forEach((field) => {
      if (field.slot === 'family' && breakdownYearData.flags[field.attribute]) {
        _flags.push({ text: field.flagText, info: field.info })
      }
    })
    setFlags(_flags)
  }, [breakdownYearData, config])

  const isLastStudent = currentStudentIndex === breakdownYearData?.students?.length - 1
  const isFirstStudent = currentStudentIndex === 0

  const handleNext = () => {
    setCurrentStudentIndex((prevIndex) => {
      // if (!isLastStudent) {
      return prevIndex + 1
      // } else {
      //   return prevIndex
      // }
    })
  }

  const handlePrevious = () => {
    setCurrentStudentIndex((prevIndex) => {
      // if (!isFirstStudent) {
      return prevIndex - 1
      // }
      // return prevIndex
    })
  }

  return (
    <>
      {showIndicative && (
        <EstimateCallout text={config.terms.futureYears} info={config.terms.futureYearsDisclaimer} icon="warning" />
      )}
      <StyledAccordion defaultExpanded={index === 0} square>
        <AccordionSummary
          expandIcon={<ArrowDropUpIcon sx={{ color: 'primary.contrastText', padding: 0 }} fontSize={'large'} />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div>
            <Typography className="fee-year-estimate" variant={'h5'}>
              {breakdownYearData.year} Fee Estimate
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="year-summary year-margins">
            <Box className="year-amount">
              {breakdownYearData.flags.indicative && (
                <Typography className="indicative">
                  INDICATIVE
                  <InfoPopup
                    style={{ marginTop: '-10px' }}
                    popId="future"
                    infos={[config.terms.futureYearsDisclaimer]}
                  />
                </Typography>
              )}
              <Typography className="indicative-amount" variant={'h1'}>
                {formatNumberCurrency(breakdownYearData.periods[periods.primary.key], 'never', config.terms)}
              </Typography>
            </Box>
            <Box sx={{ position: 'relative' }}>
              <Grid container>
                <Grid item xs={2}>
                  <BreakdownButton aria-label="open breakdown" size="medium" onClick={onViewBreakdown}>
                    <SubjectIcon fontSize="inherit" />
                  </BreakdownButton>
                </Grid>
                <Grid item xs={10}>
                  <Box style={{ position: 'relative' }}>
                    <div style={{ float: 'right', position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          left: '-30px',
                          top: '9px',
                        }}
                      >
                        <InfoPopup popId="period" infos={periodsInfos} />
                      </div>
                      {(!isMobile || config.setup.periods.available.length <= 2) && (
                        <ul style={{ paddingLeft: 0 }}>
                          {config.setup.periods.available.map((p) => (
                            <StyledPeriodButton
                              key={p.key}
                              className={`${p.key === periods.primary.key ? 'active' : ''}`}
                              onClick={() => selectPeriodHandler(p.key)}
                            >
                              <Typography>{p.title}</Typography>
                            </StyledPeriodButton>
                          ))}
                        </ul>
                      )}
                      {isMobile && config.setup.periods.available.length > 2 && (
                        <StyledPeriodOnMobile value={periods.primary.key} onChange={handleSelectPeriod}>
                          {config.setup.periods.available.map((p) => (
                            <MenuItem key={p.key} value={p.key}>
                              {p.title}
                            </MenuItem>
                          ))}
                        </StyledPeriodOnMobile>
                      )}
                    </div>
                  </Box>
                  <Box className="year-description">
                    <Typography variant={'caption'}>
                      {breakdownYearData.totals.excluded > 0 &&
                        `plus ${formatNumberCurrency(breakdownYearData.totals.excluded, 'never', config.terms)} once off - `}
                      <Link
                        href="#"
                        onClick={(e) => {
                          preventDefault(e)
                          onViewBreakdown()
                        }}
                        color="inherit"
                        sx={{
                          '&:hover': {
                            color: 'inherit',
                          },
                        }}
                      >
                        view breakdown for inclusions
                      </Link>
                    </Typography>
                    <br />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {flags.length > 0 &&
            flags.map((flag) => {
              if (!flag.text) return null
              return (
                <EstimateCallout
                  key={flag.text.replace(/[^a-zA-Z0-9]/g, '')}
                  text={flag.text}
                  info={flag.info}
                  icon="warning"
                />
              )
            })}
          {showUpdateChildName && (
            <Box sx={{ backgroundColor: '#E5F1F5', color: '#007398' }}>
              <Alert
                severity="info"
                sx={{
                  bgcolor: '#E5F1F5',
                  color: '#007398',
                  position: 'relative',
                  padding: 2,
                  paddingRight: '80px',
                }}
                action={
                  <UpdateChildNamesButton onClick={() => setIsChildNameDialogOpen(true)} size="medium">
                    <Box>
                      <EditIcon />
                    </Box>
                    update
                  </UpdateChildNamesButton>
                }
              >
                Please add the names of your children - we&apos;d love to be able to use your Child&apos;s name to
                follow up, plus it will give you a better understanding of the fees.
              </Alert>
            </Box>
          )}
          {config.features?.paymentsPanel && (
            <PaymentDetailsAccordion expanded={expanded} onChange={handleExpanded}>
              <AccordionSummary
                expandIcon={
                  expanded ? (
                    <ArrowDropUpIcon sx={{ color: theme.palette.custom.panelText }} />
                  ) : (
                    <ArrowLeftIcon sx={{ color: theme.palette.custom.panelText }} />
                  )
                }
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ p: 0 }}
              >
                <Typography>{config.features?.paymentsPanel.summary.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {config.features?.paymentsPanel.details.map((details, index) => (
                    <ListItem key={index} sx={{ py: 0 }}>
                      <MacroWrapper>
                        <ListItemText primary={<MacroRenderer dangerouslySetHtml={true} text={details.title} />} />
                      </MacroWrapper>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </PaymentDetailsAccordion>
          )}
          <Box className="year-margins" sx={{ backgroundColor: 'custom.surfaceLight', paddingTop: 2 }}>
            {breakdownYearData.students.map((currentBreakdownStudent, index) => (
              <EstimateChild
                setIsChildNameDialogOpen={setIsChildNameDialogOpen}
                currentBreakdownStudent={currentBreakdownStudent}
                showDivider={true}
                isIndicative={breakdownYearData.flags.indicative}
                key={index}
                config={config}
                periods={periods}
                setExtrasOpen={setExtrasOpen}
                currentIndex={index}
                setCurrentStudentIndex={setCurrentStudentIndex}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                isFirstStudent={isFirstStudent}
                isLastStudent={isLastStudent}
              />
            ))}
            {extrasOpen && (
              <AdditionalFields
                extrasOpen={extrasOpen}
                setExtrasOpen={setExtrasOpen}
                currentBreakdownStudent={breakdownYearData.students[currentStudentIndex]}
                config={config}
                formData={formData}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                isFirstStudent={isFirstStudent}
                isLastStudent={isLastStudent}
              />
            )}
          </Box>
          <Grid
            container
            sx={{
              backgroundColor: 'custom.surfaceLight',
              paddingBottom: 2,
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            <Grid item sx>
              {!config.extras && (
                <Button
                  variant="contained"
                  type="button"
                  size="large"
                  aria-label="open breakdown"
                  sx={{ marginRight: 1, marginBottom: 2 }}
                  onClick={onViewBreakdown}
                  startIcon={<SubjectIcon />}
                >
                  VIEW BREAKDOWN
                </Button>
              )}
              <Button
                variant="outlined"
                type="button"
                size="large"
                disableElevation={true}
                onClick={() => setCurrentPage({ page: 'editStudent' })}
                endIcon={<RepeatIcon />}
                sx={{
                  marginBottom: 2,
                  '&:hover': {
                    color: 'primary.main',
                  },
                }}
              >
                ADD OR EDIT CHILDREN
              </Button>
            </Grid>
            {config.fees.instalmentOptions && config.fees.instalmentOptions.enableInstalmentOptionSelection && (
              <Grid item sx ml={'auto'}>
                <Button
                  variant="contained"
                  type="button"
                  size="large"
                  aria-label="open breakdown"
                  sx={{ paddingLeft: 4, paddingRight: 4 }}
                  onClick={() => {
                    onValidateExtras()
                  }}
                  endIcon={<ArrowForwardIcon />}
                >
                  NEXT
                </Button>
                <QuotePage
                  isOpen={isQuotePageOpen}
                  setIsOpen={setIsQuotePageOpen}
                  breakdownYearData={breakdownYearData}
                  config={config}
                />
              </Grid>
            )}
          </Grid>
          <FeesableDialog open={extrasValidationMessage != null} size="sm">
            <Box p={4}>
              <Typography variant="h6">{extrasValidationMessage}</Typography>
              <Button
                onClick={() => setExtrasValidationMessage(null)}
                fullWidth
                sx={{
                  mt: 2,
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                    opacity: 0.8,
                  },
                }}
              >
                GO BACK AND ADD EXTRAS
              </Button>
              <Button
                sx={{
                  mt: 2,
                  backgroundColor: theme.palette.custom.primaryLight,
                  '&:hover': {
                    backgroundColor: theme.palette.custom.primaryLight,
                    opacity: 0.8,
                  },
                }}
                fullWidth
                onClick={() => setIsQuotePageOpen(true)}
              >
                PROCEED TO NEXT STEP
              </Button>
            </Box>
          </FeesableDialog>
        </AccordionDetails>
      </StyledAccordion>
    </>
  )
}

EstimateYear.propTypes = {
  breakdownYearData: PropTypes.object,
  index: PropTypes.number,
  config: PropTypes.object,
  selectPeriodHandler: PropTypes.func,
  onViewBreakdown: PropTypes.func,
  handleEditChildren: PropTypes.func,
  setIsChildNameDialogOpen: PropTypes.func,
  showUpdateChildName: PropTypes.bool,
  showIndicative: PropTypes.bool,
}

export { EstimateYear }
