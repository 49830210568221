import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import PhoneIcon from '@mui/icons-material/Phone'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  TextField,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Field } from 'formik'
import { RadioGroup } from 'formik-mui'
import { useConfig } from '../../hooks/useConfig.ts'
import { OptionalFields } from '../parts/OptionalFields.js'
import { useEffect } from 'react'

export const CONTACT_METHODS = {
  PHONE: 'phone',
  EMAIL: 'email',
  NONE: 'none',
}

const StyledTextField = styled(TextField)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(2)};
    width: 100%;
    & .MuiInput-underline {
      &:hover:before {
        border-color: ${theme.palette.primary.main};
      }
    }
    & input {
      padding-top: 13px;
      padding-bottom: 15px;
    }
  `
)

const sanitiseEmail = (value = '') => {
  // Replace one or more spaces globally with an empty string
  return value.replace(/\s+/g, '')
}

export const FormStep4 = ({ values, errors, touched, isSubmitting, fieldConfig, setFieldValue }) => {
  const config = useConfig()
  const fieldWidth = fieldConfig.parent?.general?.layout === 'singleColumn' ? 12 : 6
  const parentCustomFields = (config.fields.parent || {}).custom
    ? config.fields.parent.custom.map((f) => fieldConfig.parent[f.attribute])
    : []

  useEffect(() => {
    window.parent.postMessage({ scroll: 'SCROLL_TO_TOP' }, '*')
  }, [])

  return (
    <>
      <FormControl component="fieldset" sx={{ width: '100%' }}>
        {fieldConfig.parent.general?.label && (
          <FormControl>
            <FormLabel sx={{ color: 'inherit', marginBottom: 2 }}>{fieldConfig.parent.general?.label}</FormLabel>
          </FormControl>
        )}

        {fieldConfig.parent.contactMethod.display && (
          <Box sx={{ marginTop: 1, marginBottom: 4 }}>
            <FormControl disabled={isSubmitting}>
              <FormLabel
                sx={{ color: 'inherit', marginBottom: 2 }}
              >{`${fieldConfig.parent.contactMethod.label}*`}</FormLabel>
              <Field component={RadioGroup} name="parent.contactMethod">
                {fieldConfig.parent.contactMethod.options.map((option) => {
                  return (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio color={'primary'} />}
                      label={option.label}
                    />
                  )
                })}
              </Field>
              {touched.parent?.contactMethod && errors.parent_contactMethod && (
                <Typography>{errors.parent_contactMethod}</Typography>
              )}
            </FormControl>
          </Box>
        )}

        {fieldConfig.parent.emailParent.display && (
          <Box sx={{ marginTop: 1, marginBottom: 4 }}>
            <FormControl disabled={isSubmitting}>
              <FormLabel
                sx={{ color: 'inherit', marginBottom: 2 }}
              >{`${fieldConfig.parent.emailParent.label}*`}</FormLabel>
              <Field component={RadioGroup} name="parent.emailParent">
                {fieldConfig.parent.emailParent.options.map((option) => {
                  return (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio color={'primary'} />}
                      label={option.label}
                    />
                  )
                })}
              </Field>
              {touched.parent?.emailParent && errors.parent_emailParent && (
                <Typography>{errors.parent_emailParent}</Typography>
              )}
            </FormControl>
          </Box>
        )}
        <br />

        <Grid container spacing={1}>
          {fieldConfig.parent.firstName.display && (
            <Grid item xs={12} md={fieldWidth}>
              <Field
                name={`parent.firstName`}
                as={StyledTextField}
                sx={{ marginBottom: 1 }}
                variant="standard"
                disabled={isSubmitting}
                placeholder={`${fieldConfig.parent.firstName.label}${fieldConfig.parent.firstName.required ? '*' : ''}`}
                error={Boolean(touched.parent?.firstName && errors.parent_firstName)}
                helperText={touched.parent?.firstName && errors.parent_firstName ? errors.parent_firstName : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon sx={{ color: 'primary.main' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}

          {fieldConfig.parent.lastName.display && (
            <Grid item xs={12} md={fieldWidth}>
              <Field
                name={`parent.lastName`}
                as={StyledTextField}
                variant="standard"
                disabled={isSubmitting}
                placeholder={`${fieldConfig.parent.lastName.label}${fieldConfig.parent.lastName.required ? '*' : ''}`}
                error={Boolean(touched.parent?.lastName && errors.parent_lastName)}
                helperText={touched.parent?.lastName && errors.parent_lastName ? errors.parent_lastName : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ display: { sm: 'flex', md: fieldWidth > 6 ? 'flex' : 'none' } }}
                    >
                      <PersonIcon sx={{ color: 'primary.main' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}

          {fieldConfig.parent.email.display && (
            <Grid item xs={12} md={fieldWidth}>
              <Field
                name={`parent.email`}
                as={StyledTextField}
                variant="standard"
                disabled={isSubmitting}
                placeholder={`${fieldConfig.parent.email.label}${
                  fieldConfig.parent.email.required || values.parent.contactMethod === CONTACT_METHODS.EMAIL ? '*' : ''
                }`}
                error={Boolean(touched.parent?.email && errors.parent_email)}
                helperText={touched.parent?.email && errors.parent_email ? errors.parent_email : ''}
                onBlur={(event) => {
                  const cleanValue = sanitiseEmail(event.target.value)
                  setFieldValue('parent.email', cleanValue)
                }}
                onPaste={(event) => {
                  // Prevent default paste behavior
                  event.preventDefault()
                  // Get the clipboard data
                  const pastedData = event?.clipboardData?.getData('text')
                  // Sanitize the pasted data
                  const cleanValue = sanitiseEmail(pastedData)
                  setFieldValue('parent.email', cleanValue)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon sx={{ color: 'primary.main' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {fieldConfig.parent.phone.display && (
            <Grid item xs={12} md={fieldWidth}>
              <Field
                name={`parent.phone`}
                size={'medium'}
                as={StyledTextField}
                variant="standard"
                disabled={isSubmitting}
                placeholder={`${fieldConfig.parent.phone.label}${
                  fieldConfig.parent.phone.required || values.parent.contactMethod === CONTACT_METHODS.PHONE ? '*' : ''
                }`}
                error={Boolean(touched.parent?.phone && errors.parent_phone)}
                helperText={touched.parent?.phone && errors.parent_phone ? errors.parent_phone : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon sx={{ color: 'primary.main' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {fieldConfig.parent.postcode.display && (
            <Grid item xs={12} md={fieldWidth}>
              <Field
                name={`parent.postcode`}
                size={'medium'}
                as={StyledTextField}
                variant="standard"
                disabled={isSubmitting}
                placeholder={`${fieldConfig.parent.postcode.label}${fieldConfig.parent.postcode.required ? '*' : ''}`}
                error={Boolean(touched.parent?.postcode && errors.parent_postcode)}
                helperText={touched.parent?.postcode && errors.parent_postcode ? errors.parent_postcode : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HomeIcon sx={{ color: 'primary.main' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {parentCustomFields.length > 0 && (
            <OptionalFields slot="parent" dataFields={parentCustomFields} disabled={isSubmitting} />
          )}
        </Grid>
      </FormControl>
      <Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size={'large'}
          disableElevation={true}
          disabled={isSubmitting}
          endIcon={<ArrowForwardIcon />}
        >
          {isSubmitting ? 'calculating...' : 'VIEW ESTIMATE'}
        </Button>
      </Grid>
      <Typography sx={{ paddingTop: 2, color: 'custom.textLight', fontSize: '12px' }}>
        All information provided is collected and stored according to our{' '}
        <a target={'blank'} href={config.details.privacyUrl}>
          privacy policy
        </a>
        .
      </Typography>
    </>
  )
}
