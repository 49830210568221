import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { useConfig } from '../../hooks/useConfig'
import { useSidebar } from '../../hooks/useSidebar'
import { useSetPaymentModal } from '../../hooks/useSetPaymentModal'
import { PoweredBy } from '../parts/PoweredBy'
import { FontIcon } from '../parts/FontIcon'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import { useSetCurrentPage } from '../../hooks/useCurrentPage'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton, Tooltip } from '@mui/material'

const StyledActionsList = styled('ul')(() => ({
  paddingLeft: 4,
  margin: 0,
  '& li': {
    listStyle: 'none !important',
    margin: 0,
  },
  '& li::before': {
    display: 'none !important',
  },
}))

const CtaSegment = ({ config }) => {
  const setPaymentModal = useSetPaymentModal()

  return (
    <>
      <Typography variant={'subtitle1'} sx={{ color: 'custom.textLight' }}>
        {config.terms.ctaText}
      </Typography>

      <Box sx={{ marginTop: 2 }}>
        <StyledActionsList>
          {config.terms.ctaActions.map((action) => (
            <li key={action.text}>
              <Button
                color="primary"
                sx={{
                  textTransform: 'none',
                  color: 'grey.500',
                  fontWeight: 'normal',
                }}
                startIcon={<FontIcon sx={{ color: 'primary.main' }} iconName={action.icon}></FontIcon>}
                target="_blank"
                href={action.url}
              >
                {action.text}
              </Button>
              <br />
            </li>
          ))}
        </StyledActionsList>
        {config.terms.paymentOptions && (
          <Button
            sx={{
              marginTop: 2,
              '&:hover': {
                color: 'primary.main',
              },
            }}
            startIcon={<RequestQuoteIcon />}
            onClick={() => setPaymentModal(true)}
            variant="outlined"
          >
            {config.terms.paymentOptions.sidebarButtonText || 'Payment Information'}
          </Button>
        )}
      </Box>
    </>
  )
}

CtaSegment.propTypes = {
  config: PropTypes.object,
}

const EmailWithCopy = ({ email }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(email)
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', wordBreak: 'break-all', lineHeight: 1, margin: '4px 0' }}>
      <Typography
        sx={{
          display: 'inline-block',
          maxWidth: '200px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {email}
      </Typography>
      <Tooltip title="Copy email">
        <IconButton
          onClick={handleCopy}
          size="small"
          sx={{
            marginLeft: '8px',
            backgroundColor: 'custom.surfaceLight',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'custom.surfaceLight',
            },
          }}
        >
          <ContentCopyIcon fontSize="1em" sx={{ width: 20, height: 20 }} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

const ContactSegment = ({ config }) => {
  const getContactName = ({ contactFirstName, contactLastName, contactTitle }) => {
    let contactName = ''
    if (contactFirstName) contactName = contactFirstName
    if (contactLastName) contactName = `${contactName} ${contactLastName}`
    if (contactTitle) contactName = `${contactName}, ${contactTitle}`
    return contactName
  }
  const contactName = useMemo(() => {
    return getContactName(config.details)
  }, [config])

  return (
    <Box sx={{ color: 'custom.textLight' }}>
      <Typography>{config.terms.sidebarContactIntro}</Typography>
      <br />
      <Typography>{contactName}</Typography>
      <Typography sx={{ wordBreak: 'break-all', lineHeight: 1, margin: '4px 0' }}>
        <EmailWithCopy email={config.details.contactEmail} />
      </Typography>
      <Typography>{config.details.contactPhone}</Typography>
    </Box>
  )
}

ContactSegment.propTypes = {
  config: PropTypes.object,
}

const NotesSegment = ({ config, sidebarDisplay }) => {
  return (
    <Box sx={{ color: 'custom.textLight' }}>
      {sidebarDisplay.showFutureYearDisclaimer && (
        <>
          <Typography variant={'caption'}>^ {config.terms.futureYearsDisclaimer}</Typography>
          <br />
          <br />
        </>
      )}
      <Typography variant={'caption'}>
        <div dangerouslySetInnerHTML={{ __html: config.terms.disclaimer }} />
      </Typography>
    </Box>
  )
}

NotesSegment.propTypes = {
  config: PropTypes.object,
  sidebarDisplay: PropTypes.object,
}

const DemoSegment = () => {
  const setCurrentPage = useSetCurrentPage()
  return (
    <Box sx={{ color: 'custom.textLight' }}>
      <Button
        sx={{
          marginTop: 2,
          '&:hover': {
            color: 'primary.main',
          },
        }}
        startIcon={<RequestQuoteIcon />}
        onClick={() => setCurrentPage({ page: 'results' })}
        variant="outlined"
      >
        View Demo Estimate
      </Button>
    </Box>
  )
}

DemoSegment.propTypes = {
  config: PropTypes.object,
  sidebarDisplay: PropTypes.object,
}

const Sidebar = () => {
  const sidebarDisplay = useSidebar()
  const config = useConfig()

  const pass = { config, sidebarDisplay }
  const availableSegments = {
    contact: <ContactSegment {...pass} />,
    cta: <CtaSegment {...pass} />,
    notes: <NotesSegment {...pass} />,
    demo: <DemoSegment {...pass} />,
  }

  const segments = sidebarDisplay.display.map((segment) => availableSegments[segment])

  return (
    <Box sx={{ backgroundColor: 'custom.primaryLight', padding: 2 }}>
      {segments.map((s, i) => (
        <div key={i}>
          {s}
          <Divider
            sx={{
              backgroundColor: 'primary.main',
              opacity: '0.25',
              marginTop: '26px',
              marginBottom: '26px',
            }}
          />
        </div>
      ))}
      <PoweredBy hideLogo={false} />
    </Box>
  )
}

export { Sidebar }
