import { ArrowBackIos, ArrowForwardIosOutlined } from '@mui/icons-material'
import ChatIcon from '@mui/icons-material/Chat'
import { Box, Button, DialogContent, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useCalcResponse } from '../../hooks/useCalcResponse'
import { formatNumberCurrency } from '../../services/TextHelper'
import FeesableDialog from '../FeesableDialog/FeesableDialog'
import { EstimateCallout } from '../parts/EstimateCallout'
import { InfoPopup } from '../parts/InfoPopup'
import { EstimateBreakdownChild, StyledChildRow } from './EstimateBreakdownChild'

const StyledContentWrapper = styled(DialogContent)(({ theme }) => ({
  fontSize: '14px',
  '& .col-left': {
    width: '100%',
  },
  '& .col-right1': {
    color: theme.palette.custom.primaryMedium,
    whiteSpace: 'nowrap',
  },
  '& .col-right2': {
    width: '112px',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  '& .colRight2>p': {
    textAlign: 'right!important',
    whiteSpace: 'nowrap',
  },
  '& .col-header-right2': {
    textTransform: 'uppercase',
    fontSize: '0.9em',
    color: theme.palette.custom.textMedium,
  },
  '& .col-header-right1': {
    textTransform: 'uppercase',
    fontSize: '0.9em',
    color: theme.palette.custom.textLight,
  },
  '& .notesWrapper': {
    marginTop: theme.spacing(2),
    color: theme.palette.custom.textLight,
    '& span': {
      fontSize: '11px',
      lineHeight: 1.5,
    },
  },
  '& .notes': {
    marginTop: '4px',
    marginLeft: 0,
    paddingLeft: 0,
    listStyle: 'none',
    '& li': {
      paddingBottom: '8px',
    },
  },
  '& .noteRef': {
    width: '10px',
    display: 'inline-block',
    fontWeight: 'bold',
  },
}))

const StyledColumnHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'right',
  paddingTop: theme.spacing(1),
  paddingBottom: 0,
  marginBottom: '-14px',
  color: theme.palette.custom.textMedium,
}))

const StyledTotalWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: '1px solid',
  borderBottom: '1px solid',
  borderColor: theme.palette.primary.main,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: theme.palette.custom.primary,

  '& .colRight2>p': {
    textAlign: 'right!important',
  },
}))

const StyledModifiedTotalWrapper = styled(Box)(({ theme }) => ({
  borderTop: '1px solid',
  borderBottom: '1px solid',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(2),

  '& .totalAdditionalItemsHeader': {
    display: 'flex',
    color: theme.palette.custom.textMedium,
  },
}))

const StyledBreakdownItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 0,
  color: theme.palette.custom.textMedium,

  '& .breakdown-item-title': {
    color: theme.palette.custom.textLight,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: '28px',
  },

  '& .breakdownItemValue': {
    color: theme.palette.custom.textMedium,
    fontSize: '13px',
  },
}))

const StyledAdditionalItems = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(3),

  '& .additionalItemsHeader': {
    display: 'flex',
    color: theme.palette.custom.textMedium,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  '& .refs': {
    fontSize: '0.8em',
    paddingLeft: '5px',
    marginTop: '-10px',
  },
}))

const StyledFeedbackButton = styled(Button)(() => ({
  textTransform: 'uppercase',
  minWidth: '180px',
  marginLeft: '8px',
}))

const StyledAlertMessage = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}))

const StyledFeedbackSection = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.custom.surfaceMedium,
  padding: theme.spacing(2),
  width: '100%',
}))

const StyledInstalmentOptions = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.custom.surfaceMedium,

  '& .additionalItemsHeader': {
    display: 'flex',
    color: theme.palette.custom.textMedium,
    marginBottom: theme.spacing(1),
  },

  '& .refs': {
    fontSize: '0.8em',
    paddingLeft: '5px',
    marginTop: '-10px',
  },
}))

const StyledNavigationIconLeft = styled('div')(({ theme }) => ({
  backgroundColor: '#E1E1E4',
  padding: theme.spacing(3),
  borderRadius: '50%',
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  left: '25%',
  [theme.breakpoints.down('xl')]: {
    left: '20%',
  },
  [theme.breakpoints.down('lg')]: {
    left: '10%',
  },
  [theme.breakpoints.down('md')]: {
    left: '5%',
  },
}))

const StyledNavigationIconRight = styled('div')(({ theme }) => ({
  backgroundColor: '#E1E1E4',
  padding: theme.spacing(3),
  borderRadius: '50%',
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  right: '25%',
  [theme.breakpoints.down('xl')]: {
    right: '20%',
  },
  [theme.breakpoints.down('lg')]: {
    right: '10%',
  },
  [theme.breakpoints.down('md')]: {
    right: '5%',
  },
}))

const StyledBoxContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

const EstimateBreakdown = ({
  yearData,
  open,
  onCloseBreakdown,
  config,
  periods,
  isNextEnabled,
  isPrevEnabled,
  onNext,
  onPrev,
}) => {
  const calcResponse = useCalcResponse()
  const [infoCloseFnRef, setInfoCloseFnRef] = useState(null)
  //Hardcoded to 825px because of the custom button layout
  const isSmallScreen = useMediaQuery('(max-width:825px)')

  useEffect(() => {
    window.parent.postMessage({ scroll: 'SCROLL_TO_TOP' }, '*')
  }, [])

  const onCloseModal = () => {
    onCloseBreakdown()
    infoCloseFnRef && infoCloseFnRef()
  }

  const onClickFeedback = () => {
    try {
      window.open(
        `${config.feedback.cta.link}?schoolId=${config.schoolId}&submissionId=${calcResponse.data?.submissionId}`,
        '_blank'
      )
    } catch (e) {
      console.error(e)
    }
  }

  if (!yearData || !periods.year) return null

  const buildFamilyLineItems = (location, includeTitle = false) => {
    if (yearData.lines[location].length === 0) return null

    const isInTotal = location === 'inTotal'
    const isAfterTotal = location === 'afterTotal'
    const hasMoreThanOneLineItem = Array.from(yearData.lines[location]).length > 1
    const getSubTotal = () =>
      yearData.lines[location].reduce((acc, item) => {
        return acc + (item.amt || 0)
      }, 0)

    const subTotal = getSubTotal()

    return (
      <StyledAdditionalItems>
        {includeTitle && (
          <div className="additionalItemsHeader">
            <div className="col-left">
              <Typography>
                <strong>Additional Items for {yearData.year}</strong>
              </Typography>
            </div>
            <div className="col-right2 col-header-right2">
              <Typography>
                <strong>Once Off</strong>
              </Typography>
            </div>
          </div>
        )}
        {yearData.lines[location].map((line, index) => (
          <StyledBreakdownItem key={index}>
            <StyledBoxContainer>
              <Typography variant={'body2'} className="breakdown-item-title">
                {line.title}
                {line.refs && line.refs.length > 0 && <sup className="refs">[{line.refs.join(',')}]</sup>}
              </Typography>
              {line.info && (
                <Box sx={{ zIndex: 9999 }}>
                  <InfoPopup popId={line.key} infos={[line.info]} getCloseInfoRef={(cb) => setInfoCloseFnRef(cb)} />
                </Box>
              )}
            </StyledBoxContainer>
            <Typography variant={'body2'} className="breakdown-item-value">
              {formatNumberCurrency(line.amt, rounding?.yearTotal?.displayDecimals, config.terms)}
            </Typography>
          </StyledBreakdownItem>
        ))}
        {isInTotal && hasMoreThanOneLineItem && subTotal > 0 && (
          <Box sx={{ borderBottom: '1px solid #dcdcdc' }}>
            <StyledChildRow key="inTotalOtherFees" className="childRowTotal">
              <div className="colLeft">
                <Typography variant={'body2'} className="childRowTotalTitle">
                  <strong>Total Additional Items</strong>
                </Typography>
              </div>
              <div className="colRight2">
                <Typography variant={'body2'} className="childRowTotalValue">
                  <strong>{formatNumberCurrency(subTotal, rounding?.subTotal?.displayDecimals, config.terms)}</strong>
                </Typography>
              </div>
            </StyledChildRow>
          </Box>
        )}
        {isAfterTotal && hasMoreThanOneLineItem && subTotal > 0 && (
          <StyledModifiedTotalWrapper>
            <div className="totalAdditionalItemsHeader">
              <div className="col-left">
                <Typography>
                  <strong>Total Additional Items</strong>
                </Typography>
              </div>
              <div className="col-right2 col-header-right2">
                <Typography fontWeight={300}>
                  <strong>{formatNumberCurrency(subTotal, rounding?.subTotal?.displayDecimals, config.terms)}</strong>
                </Typography>
              </div>
            </div>
          </StyledModifiedTotalWrapper>
        )}
      </StyledAdditionalItems>
    )
  }

  const getCallout = () => {
    if (!yearData.flags.indicative) return null

    return <EstimateCallout text={config.terms.futureYears} info={config.terms.futureYearsDisclaimer} icon="warning" />
  }

  const rounding = config.features.move.setup.rounding
  const downloadButtonFilename = config.features.download?.breakdown?.enabled
    ? `${config.features.download.breakdown.filePrefix}${yearData.year} Fees - ${calcResponse?.data?.submissionId}`
    : ''

  return (
    <>
      <FeesableDialog
        open={open}
        onCloseModal={onCloseModal}
        modalTitle={`${yearData.year} Fee Breakdown`}
        size="sm"
        onNext={isNextEnabled ? onNext : null}
        onPrev={isPrevEnabled ? onPrev : null}
        isSmallScreen={isSmallScreen}
        downloadButtonFilename={downloadButtonFilename}
      >
        {getCallout()}
        <StyledContentWrapper>
          <StyledColumnHeader>
            <div className="col-left">&nbsp;</div>
            {periods.nonYear && (
              <div className="col-right1 col-header-right1">
                <Typography variant="body">
                  <strong>{periods.nonYear.title}</strong>
                </Typography>
              </div>
            )}
            <div className="col-right2 col-header-right2">
              <Typography variant="body">
                <strong>{periods.year.title}</strong>
              </Typography>
            </div>
          </StyledColumnHeader>
          {yearData.students.map((student, index) => (
            <EstimateBreakdownChild student={student} key={index} index={index} periods={periods} config={config} />
          ))}

          {buildFamilyLineItems('inTotal')}

          <StyledTotalWrapper>
            <Typography className="col-left">Total {yearData.year} Fee Estimate</Typography>
            {periods.nonYear && (
              <div className="col-right1">
                <Typography variant="body">
                  <strong>{formatNumberCurrency(yearData.periods[periods.nonYear.key], 'never', config.terms)}</strong>
                </Typography>
              </div>
            )}
            <div className="col-right2">
              <Typography>
                <strong>
                  {formatNumberCurrency(
                    yearData.periods[periods.year.key],
                    rounding?.yearTotal?.displayDecimals,
                    config.terms
                  )}
                </strong>
              </Typography>
            </div>
          </StyledTotalWrapper>

          {buildFamilyLineItems('afterTotal', true)}

          {yearData?.instalments?.length > 0 && (
            <StyledInstalmentOptions>
              <div className="additionalItemsHeader">
                <div className="col-left">
                  <Typography>
                    <strong>Instalment Options</strong>
                  </Typography>
                </div>
              </div>

              {yearData?.instalments?.map((item, index) => (
                <>
                  <StyledBreakdownItem key={index}>
                    <StyledBoxContainer>
                      <Typography variant={'body2'} className="breakdown-item-title">
                        {item.label}
                      </Typography>
                      {item.info && (
                        <Box sx={{ zIndex: 9999 }}>
                          <InfoPopup
                            popId={item.key}
                            infos={[item.info]}
                            getCloseInfoRef={(cb) => setInfoCloseFnRef(cb)}
                          />
                        </Box>
                      )}
                    </StyledBoxContainer>
                    <Typography variant={'body2'} className="breakdown-item-value">
                      {formatNumberCurrency(item.amount, 'ifPresent', config.terms)}
                    </Typography>
                  </StyledBreakdownItem>
                </>
              ))}
            </StyledInstalmentOptions>
          )}

          {config?.feedback && (
            <StyledFeedbackSection>
              <StyledAlertMessage>
                <strong>{config.feedback.description}</strong>
                <StyledFeedbackButton variant="contained" startIcon={<ChatIcon />} onClick={onClickFeedback}>
                  {config.feedback.cta?.label}
                </StyledFeedbackButton>
              </StyledAlertMessage>
            </StyledFeedbackSection>
          )}

          <Box className="notesWrapper">
            {yearData.notes.filter((n) => n.amt > 0).length > 0 && (
              <Typography variant="caption">
                <strong>Other Fees &amp; Levies</strong>
              </Typography>
            )}
            <ul className="notes">
              {yearData.notes.map((n) => {
                let text = n.title || n.note
                if (n.amt) text = `${text} <strong>${formatNumberCurrency(n.amt, 'never', config.terms)}</strong>`
                if (n.ref) text = `<span class="noteRef">${n.ref}</span> ${text}`
                return (
                  <li key={n.ruleId}>
                    <Typography variant="caption">
                      <div dangerouslySetInnerHTML={{ __html: text }} />
                    </Typography>
                  </li>
                )
              })}
            </ul>
          </Box>
          <Typography variant="caption" sx={{ color: 'custom.textLight' }}>
            {config.name} | © {new Date().getFullYear()} Feesable
          </Typography>

          {!isSmallScreen && open && (
            <>
              <Box sx={{ position: 'fixed', top: '50%', right: '50%' }}>
                <StyledNavigationIconLeft onClick={onPrev} sx={{ visibility: isPrevEnabled ? 'visible' : 'hidden' }}>
                  <ArrowBackIos sx={{ color: '#86868B', fontSize: 'inherit' }} />
                </StyledNavigationIconLeft>
                <StyledNavigationIconRight onClick={onNext} sx={{ visibility: isNextEnabled ? 'visible' : 'hidden' }}>
                  <ArrowForwardIosOutlined sx={{ color: '#86868B', fontSize: 'inherit' }} />
                </StyledNavigationIconRight>
              </Box>
            </>
          )}
        </StyledContentWrapper>
      </FeesableDialog>
    </>
  )
}

EstimateBreakdown.propTypes = {
  yearData: PropTypes.object,
  open: PropTypes.bool,
  onCloseBreakdown: PropTypes.func,
  isNextEnabled: PropTypes.bool.isRequired,
  isPrevEnabled: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  config: PropTypes.object,
  periods: PropTypes.object,
  flags: PropTypes.array,
}

export { EstimateBreakdown }
