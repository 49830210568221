import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Box, Grid, Button, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { useConfig } from '../../hooks/useConfig.ts'
import { useActiveStudentIndex } from '../../hooks/useActiveStudentIndex.ts'
import { StudentsList } from '../parts/StudentsList'
import { StudentForm } from '../parts/StudentForm'
import { OptionalFields } from '../parts/OptionalFields'

const StyledFormControls = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: row;

    ${theme.breakpoints.down('lg')} {
      display: block;
      & button: {
        width: 100%;
        marginLeft: 0;
        marginBottom: theme.spacing(2)
      }
    }
  `
)

const FormStep2 = ({ values, errors, touched, addAnotherChild, editChild, editIndex, fieldConfig }) => {
  const config = useConfig()
  const activeStudentIndex = useActiveStudentIndex()

  if (activeStudentIndex === null || activeStudentIndex === undefined) return null

  const handleAddAnotherChild = () => {
    addAnotherChild(values)
  }

  const familyCustomFields = config.fields.family.custom.map((f) => fieldConfig.family[f.attribute])

  return (
    <>
      <Grid item md={8}>
        <StudentForm
          key={activeStudentIndex}
          student={values.students[activeStudentIndex]}
          activeStudentIndex={activeStudentIndex}
          fieldConfig={fieldConfig}
        />
      </Grid>

      <Grid
        item
        md={8}
        sx={{
          marginTop: 2,
          paddingTop: 2,
          borderTop: 1,
          borderTopColor: 'custom.surfaceDark',
        }}
      >
        <StudentsList values={values} editChild={editChild} editIndex={editIndex} config={config} />
        <Button
          sx={{
            marginBottom: 1,
            marginTop: 1,
            width: '100%',
            justifyContent: 'start',
            padding: 2,
            paddingLeft: 3,
            paddingRight: 3,
            '&:hover': {
              color: 'primary.main',
            },
          }}
          variant="outlined"
          color="primary"
          type="button"
          onClick={() => handleAddAnotherChild()}
          size="large"
          disableElevation={true}
          startIcon={<PersonAddIcon />}
        >
          Add another {config.terms.child}
        </Button>
      </Grid>

      <Grid item md={8}>
        {familyCustomFields.length > 0 && <OptionalFields slot="family" dataFields={familyCustomFields} />}
      </Grid>

      <StyledFormControls>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          disableElevation={true}
          endIcon={<ArrowForwardIcon />}
        >
          NEXT
        </Button>
      </StyledFormControls>
      {(errors.data ||
        (touched.students &&
          touched.students[activeStudentIndex] &&
          touched.students[activeStudentIndex].grade &&
          errors.grade) ||
        (touched.students &&
          touched.students[activeStudentIndex] &&
          touched.students[activeStudentIndex].startYear &&
          errors.startYear) ||
        (touched.students &&
          touched.students[activeStudentIndex] &&
          touched.students[activeStudentIndex].current &&
          errors.current)) && (
        <Typography sx={{ color: 'error.main', paddingTop: 1, fontSize: 16 }}>
          * Please fill in all fields above to confirm and move to next step.
        </Typography>
      )}
    </>
  )
}

FormStep2.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  addAnotherChild: PropTypes.func,
  editChild: PropTypes.func,
  editIndex: PropTypes.number,
  siteState: PropTypes.object,
  fieldConfig: PropTypes.object,
}

export { FormStep2 }
