import _ from 'lodash'
import { emailRegex } from '../shared/constants.ts'
import { Estimate } from './estimate/Estimate.js'
import { Form, Formik } from 'formik'
import { FormStep1 } from './steps/FormStep1'
import { FormStep2 } from './steps/FormStep2'
import { FormStep3 } from './steps/FormStep3'
import { FormStep4 } from './steps/FormStep4'
import { PaymentOptions } from './estimate/PaymentOptions.js'
import { Sidebar } from './layout/Sidebar.js'
import { styled } from '@mui/material/styles'
import { useActiveStudentIndex } from '../hooks/useActiveStudentIndex.ts'
import { useCalcResponse } from '../hooks/useCalcResponse.ts'
import { useConfig } from '../hooks/useConfig.ts'
import { useEffect, useRef, useState } from 'react'
import { useFormData } from '../hooks/useFormData.ts'
import { useSetActiveStudentIndex } from '../hooks/useSetActiveStudentIndex.ts'
import { useSetFormData } from '../hooks/useSetFormData.ts'
import { useSetSidebar } from '../hooks/useSetSidebar.ts'
import { useSidebar } from '../hooks/useSidebar.ts'
import { v4 as uuidv4 } from 'uuid'
import { Grid } from '@mui/material'
import { useCurrentPage, useSetCurrentPage } from '../hooks/useCurrentPage.ts'

const StyledWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#ffffff',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: theme.spacing(3),
  textAlign: 'left',

  '&.include-padding': {
    padding: theme.spacing(3),
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}))

const renderPage = ({ page, ...passThru }) => {
  const component = {
    splash: <FormStep1 {...passThru} />,
    students: <FormStep2 {...passThru} />,
    editStudent: <FormStep3 {...passThru} />,
    parent: <FormStep4 {...passThru} />,
    results: <Estimate {...passThru} />,
  }
  return <>{component[page] || component['results']}</>
}

// If a child is changed in a way that would affect the extras available to them,
// or the pricing, we should remove the extras so they need to re - populate them later
const invalidateExtras = (currentData, newData) => {
  if (!currentData || !currentData.students || currentData.students.length === 0) return newData

  const students = []
  newData.students.forEach((student) => {
    const match = currentData.students.find((cS) => cS.id === student.id)
    if (!match) {
      students.push(student)
    } else {
      students.push(
        student.grade !== match.grade || student.site !== match.site || student.startYear !== match.startYear
          ? { ...student, extras: [] }
          : student
      )
    }
  })
  return { ...newData, students }
}

const MultiStepForm = ({ validator }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const currentPage = useCurrentPage()
  const setCurrentPage = useSetCurrentPage()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [editIndex, setEditIndex] = useState(null)
  const [fieldConfig, setFieldConfig] = useState({})
  const [initialValues, setInitialValues] = useState({})
  const [suppress, setSuppress] = useState(urlParams.get('suppress'))
  const [source, setSource] = useState(urlParams.get('source'))
  const activeStudentIndex = useActiveStudentIndex(0)
  const setActiveStudentIndex = useSetActiveStudentIndex()
  const setFormData = useSetFormData()
  const formData = useFormData()
  const calcResponse = useCalcResponse()
  const sidebarDisplay = useSidebar()
  const setSidebar = useSetSidebar()
  const config = useConfig()
  const formRef = useRef()
  const wrapperRef = useRef(null)
  const [type, setType] = useState(urlParams.get('type') || config.type)

  useEffect(() => {
    const handleHashChange = () => {
      const hashParams = window.location.hash.substring(1).split('#')

      const params = {
        type: urlParams.get('type') || undefined,
        suppress: urlParams.get('suppress') || undefined,
        source: urlParams.get('source') || undefined,
      }

      hashParams.forEach((param) => {
        const [key, value] = param.split('=')
        if (!params[key]) {
          params[key] = value
        }
      })

      setType(params.type || 'live')
      setSuppress(params.suppress)
      if (params.source) setSource(params.source)
    }

    window.addEventListener('hashchange', handleHashChange)
    handleHashChange()
    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [])

  // Get width of parent container (not screen) to choose whether to wrap the sidebar
  useEffect(() => {
    if (wrapperRef.current) {
      let parentWidth = wrapperRef.current.offsetWidth
      const width = parentWidth > 1920 ? 3 : parentWidth < 1000 ? 12 : 4
      setSidebar({ width })
    }
  }, [wrapperRef])

  // Move to next screen once submitted and received valid response
  useEffect(() => {
    if (calcResponse.data && !calcResponse.submissionError && hasSubmitted && calcResponse.isLoading === false) {
      changeStep('results')
    }
  }, [calcResponse, hasSubmitted])

  useEffect(() => {
    if (formData.students?.length) {
      setInitialValues(_.cloneDeep(formData))
    }
  }, [formData])

  // Setup initial Form values
  useEffect(() => {
    if (!config?.schoolId) return null

    const fields = config?.fields

    const data = {
      schoolId: config?.schoolId,
      parent: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        postcode: '',
        contactMethod: '',
        emailParent: '',
        code: '',
        comment: '',
      },
      family: fields.family.custom?.reduce((acc, f) => {
        acc[f.attribute] = f.default
        return acc
      }, {}),
      students: [getNewStudent(fields.student.custom)],
    }
    // TODO: Do this properly. Just having to avoid nested promises here. Need to clean up form value setting
    if (config?.testMode) {
      data.students = JSON.parse(JSON.stringify(config?.inputData.students))
      data.parent = JSON.parse(JSON.stringify(config?.inputData.parent))
      setFormData(data)
    }

    setInitialValues(data)
  }, [config])

  const changeStep = (page) => {
    wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    setCurrentPage({ page })
  }

  const handleSubmit = async (values) => {
    const cleanedValues = removeHiddenCustomFields(values)
    if (currentPage.page === 'parent') {
      setFormData(invalidateExtras(formData, { ...cleanedValues, type, suppress, source }), !!config?.extras)
      setHasSubmitted(true)
    } else {
      changeStep('parent')
    }
  }

  const getNewStudent = (studentCustomFields = []) => {
    const dt = new Date()
    let year = dt.getFullYear()

    // if month >= July we presume following year default
    if (dt.getMonth() > 5) year += 1

    const studentFields = config?.fields.student
    return {
      id: uuidv4(),
      name: '',
      grade: '',
      startYear: year,
      current: studentFields.currentStudent.default,
      currentStudent: studentFields.currentStudent.default ? 'true' : 'false',
      variant: '',
      site: config?.options.sites.length === 1 ? 'default' : '',
      ...studentCustomFields.reduce((acc, f) => {
        acc[f.attribute] = f.default
        return acc
      }, {}),
    }
  }

  const removeHiddenCustomFields = (values) => {
    const clonedValues = _.cloneDeep(values)

    const removeFields = (type) => {
      const customFields = config?.fields[type]?.custom
      if (!customFields) return

      customFields.forEach((field) => {
        const fieldConfigurations = fieldConfig[type][field.attribute]
        if (fieldConfigurations?.display === false) {
          if (type === 'student') {
            delete clonedValues.students[activeStudentIndex][field.attribute]
          } else {
            delete clonedValues[type][field.attribute]
          }
        }
      })
    }

    removeFields('student')
    removeFields('family')

    return clonedValues
  }

  const validate = (values) => {
    const { students, parent } = values
    const errors = {}
    const activeStudent = students[activeStudentIndex]
    if (!activeStudent) return {}

    const validateCustomFields = (slot) => {
      const customFields = config?.fields[slot].custom
      if (!customFields) return

      const addErrorOnSlot = (slot, attribute, message) => {
        if (!errors[slot]) errors[slot] = {}
        errors[slot][attribute] = message
      }

      customFields.forEach((field) => {
        const value = (slot === 'student' ? activeStudent : values[slot])[field.attribute]
        const fieldConfigurations = fieldConfig[slot][field.attribute]

        if (fieldConfigurations.required && !value) {
          addErrorOnSlot(slot, field.attribute, '*Required')
          // Skip further validation if field is empty and required
          return
        }

        if (fieldConfigurations.type === 'textfield' && value && Array.isArray(fieldConfigurations.validation)) {
          fieldConfigurations.validation.forEach((validation) => {
            if (validation.type === 'regex' && !new RegExp(validation.pattern).test(value)) {
              addErrorOnSlot(slot, field.attribute, validation.message)
            }
          })
        }

        if (fieldConfigurations.type === 'userDefined' && Array.isArray(value) && value.length > 0) {
          value.forEach((row) => {
            if (row.text.length > 40) addErrorOnSlot(slot, field.attribute, 'Name is limited to 40 characters')
            if (row.value && row.value < 0) addErrorOnSlot(slot, field.attribute, 'Please set a valid value')
            if (row.type === 'percent' && row.value > 100)
              addErrorOnSlot(slot, field.attribute, 'Maximum percentage is 100')
          })
        }
      })
    }

    if (currentPage.page === 'students') {
      if (!activeStudent.grade) {
        errors.grade = true
      }

      if (!activeStudent.startYear) {
        errors.startYear = true
      }

      if ((config?.options.sites || []).length > 1 && !activeStudent.site) {
        errors.site = '*Required'
      }

      const site = config?.lookup[activeStudent.site]
      if (site) {
        const grade = site.grades.find((g) => g.grade === activeStudent.grade) || {}
        if (grade && grade.variants && !activeStudent.variant) errors.variant = '*Required'
      }

      if (activeStudent.current === null) {
        errors.current = '*Required'
      }
      validateCustomFields('student')
      validateCustomFields('family')
    } else if (currentPage.page === 'parent') {
      Object.keys(fieldConfig.parent).forEach((key) => {
        if (fieldConfig.parent[key].required && !parent[key]) {
          errors[`parent_${key}`] = '*Required'
        }
      })

      if (parent.email && !emailRegex.test(parent.email)) {
        errors.parent_email = 'Please provide a valid email address'
      }

      if (parent.phone && parent.phone.length < 8) {
        errors.parent_phone = 'Please provide a valid phone number for us to contact you'
      }
      validateCustomFields('parent')
    }
    return errors
  }

  return (
    <StyledWrapper className={config?.includePadding && 'include-padding'} ref={wrapperRef}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{ ...initialValues }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ values, errors, touched, setErrors, setTouched, setFieldValue }) => {
          const validateBeforeEdit = (values) => {
            const studentErrors = validate(values)
            if (Object.keys(studentErrors).length) {
              setErrors(studentErrors)
              setTouched({
                ...touched,
                students: values.students.map(() => ({
                  name: true,
                  grade: true,
                  startYear: true,
                  current: true,
                  variant: true,
                })),
              })
              return false
            }
            return true
          }
          const addAnotherChild = (values) => {
            if (validateBeforeEdit(values)) {
              setFieldValue('students', [...values.students, getNewStudent(config?.fields.student.custom)])
              setActiveStudentIndex(values.students.length)
              changeStep('students')
            }
          }

          const editChild = (index, action, values) => {
            if (!validateBeforeEdit(values)) return false

            if (action === 'edit') {
              setActiveStudentIndex(index)
              changeStep('students')
              setEditIndex(index)
            }

            if (action === 'delete') {
              if (values.students.length < 2) addAnotherChild(values)
              setActiveStudentIndex(0)
              setEditIndex(0)
              setFieldValue(
                'students',
                values.students.filter((s, i) => i !== index)
              )
            }
          }

          const passThruProps = {
            page: currentPage.page,
            values,
            setFieldValue,
            errors,
            touched,
            isSubmitting: calcResponse.isLoading,
            addAnotherChild,
            editChild,
            editIndex,
          }

          useEffect(() => {
            if (config?.fields) {
              validator.setConfig(config?.fields)
            }
          }, [config])

          useEffect(() => {
            if (config?.fields && values.students) {
              if (validator.fieldConfig) {
                validator.setConditionals(values)
                setFieldConfig({
                  family: validator.getModifiedFieldConfig('family'),
                  parent: validator.getModifiedFieldConfig('parent'),
                  student: validator.getModifiedFieldConfig('student', activeStudentIndex),
                })
              }
            }
          }, [config, values, activeStudentIndex])

          useEffect(() => {
            if (!fieldConfig.parent) return
            ;['emailParent', 'contactMethod'].forEach((k) => {
              if (fieldConfig.parent[k].display) {
                if (values.parent[k] === '') {
                  setFieldValue(`parent.${k}`, fieldConfig.parent[k].default, false)
                }
              } else {
                if (values.parent[k] !== '') {
                  setFieldValue(`parent.${k}`, '', false)
                }
              }
            })
          }, [fieldConfig])

          const bodyWidth = sidebarDisplay.width === 12 ? 12 : 12 - sidebarDisplay.width
          return (
            <Form style={{ width: '100%' }}>
              {sidebarDisplay.show && (
                <Grid container spacing={3}>
                  <Grid item xs={bodyWidth}>
                    {renderPage({ ...passThruProps, fieldConfig })}
                  </Grid>
                  <Grid item xs={sidebarDisplay.width}>
                    <Sidebar />
                  </Grid>
                </Grid>
              )}
              {!sidebarDisplay.show && renderPage({ ...passThruProps, fieldConfig })}
            </Form>
          )
        }}
      </Formik>
      {config?.terms.paymentOptions && <PaymentOptions config={config} />}
    </StyledWrapper>
  )
}

export { MultiStepForm }
