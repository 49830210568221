import {
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Field, useFormikContext } from 'formik'
import { InfoPopup } from '../parts/InfoPopup'
import { FontIcon } from './FontIcon'
import CustomUserDefined from './CustomUserDefined'

const StyledBoxContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}))

const StyledTextField = styled(TextField)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(2)};
    width: 100%;
    & .MuiInput-underline {
      &:hover:before {
        border-color: ${theme.palette.primary.main};
      }
    }
    & input {
      padding-top: 13px;
      padding-bottom: 15px;
    }
  `
)

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',
  maxWidth: theme.breakpoints.values.sm,
}))

const fieldIconStyle = {
  color: 'primary.main',
}

const OptionalFields = ({ slot, dataFields, activeStudentIndex = null, disabled = false }) => {
  const { errors, touched } = useFormikContext()
  const fieldPrefix = slot === 'student' ? `students[${activeStudentIndex}]` : slot

  return (
    <Grid
      item
      xs={12}
      sx={{
        marginTop: 0,
        paddingTop: 0,
        paddingBottom: 1,
        marginBottom: 0,
      }}
    >
      {dataFields.map((field) => {
        if (!field.display) return null
        const isTouched = touched[fieldPrefix] ? touched[fieldPrefix][field.attribute] : null
        const hasError = errors[fieldPrefix] ? errors[fieldPrefix][field.attribute] : null
        const shouldShowError = isTouched && hasError
        const label = field.info ? (
          <StyledBoxContainer>
            {field.label}
            <InfoPopup popId={field.attribute} infos={[field.info]} />
          </StyledBoxContainer>
        ) : (
          field.label
        )
        return (
          <Box sx={{ marginTop: 0, marginBottom: 0 }} key={`wrap_${field.attribute}`}>
            {field.type === 'checkbox' && (
              <StyledBox>
                <Field
                  key={field.attribute}
                  name={`${fieldPrefix}.${field.attribute}`}
                  as={FormControlLabel}
                  type="checkbox"
                  error={Boolean(shouldShowError)}
                  helperText={hasError}
                  control={<Checkbox />}
                  label={label}
                  variant="standard"
                  sx={{ width: '100%' }}
                />
              </StyledBox>
            )}
            {field.type === 'select' && (
              <StyledBox>
                <FormLabel>
                  {field.label}
                  {field.info && <InfoPopup popId={field.attribute} infos={[field.info]} />}
                </FormLabel>
                <Field
                  name={`${fieldPrefix}.${field.attribute}`}
                  disableUnderline={true}
                  displayEmpty={true}
                  sx={{ width: '100%' }}
                  as={Select}
                  variant="filled"
                  error={Boolean(shouldShowError)}
                  helperText={hasError}
                >
                  {field.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </StyledBox>
            )}
            {field.type === 'textfield' && (
              <StyledBox>
                <FormLabel>
                  {field.label}
                  {field.info && <InfoPopup popId={field.attribute} infos={[field.info]} />}
                </FormLabel>
                <Field
                  key={field.attribute}
                  name={`${fieldPrefix}.${field.attribute}`}
                  disabled={disabled}
                  size={'medium'}
                  as={StyledTextField}
                  variant="standard"
                  placeholder={`${field.required ? '*' : ''}`}
                  error={Boolean(shouldShowError)}
                  helperText={hasError}
                  InputProps={
                    field.icon
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontIcon sx={fieldIconStyle} iconName={field.icon} />
                            </InputAdornment>
                          ),
                        }
                      : undefined
                  }
                />
              </StyledBox>
            )}
            {field.type === 'number' && (
              <StyledBox>
                <FormLabel>
                  {field.label}
                  {field.info && <InfoPopup popId={field.attribute} infos={[field.info]} />}
                </FormLabel>

                <Field
                  key={field.attribute}
                  name={`${fieldPrefix}.${field.attribute}`}
                  type="number"
                  disabled={disabled}
                  size="medium"
                  as={StyledTextField}
                  variant="standard"
                  placeholder={`${field.required ? '*' : ''}`}
                  error={Boolean(shouldShowError)}
                  helperText={hasError}
                  onWheel={(e) => e.target.blur()}
                  InputProps={
                    field.icon
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontIcon sx={fieldIconStyle} iconName={field.icon} />
                            </InputAdornment>
                          ),
                        }
                      : undefined
                  }
                />
              </StyledBox>
            )}
            {field.type === 'userDefined' && (
              <Field
                key={field.attribute}
                name={`${fieldPrefix}.${field.attribute}`}
                field={field}
                error={errors[slot] && errors[slot][field.attribute]}
                as={CustomUserDefined}
                sx={{ width: '100%' }}
              />
            )}
          </Box>
        )
      })}
    </Grid>
  )
}

export { OptionalFields }
